//import config from "./config";
/**
 * calls the API based on the configuration
 */
class Api {
  async sendBooking(data) {
    return this.call("booking", data);
  }

  async sendContact(data) {
    return this.call("contact", data);
  }

  async call(method, calldata) {
    if (!calldata)
      return fetch(`/Api.php?action=${method}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      }).then((response) => response.json());
    else {
      console.log(JSON.stringify(calldata));
      let formdata = "";
      Object.keys(calldata).forEach((k) => {
        if (formdata) formdata += "&";
        formdata += `${k}=${encodeURIComponent(calldata[k])}`;
      });
      return fetch(`/Api.php?action=${method}`, {
        method: "POST", // or 'PUT'
        body: formdata, // data can be `string` or {object}!
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((response) => response.json());
    }
  }
  async ckUpload(image) {
    var data = new FormData();
    data.append("image", image);
    console.dir(data);
    return fetch(`/api.php?action=ckupload`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      body: data,
    })
      .then((response) => response.json())
      .catch((e) => {
        console.error(e);
      });
  }
  async uploadImages(image, imageBlurred, projectId) {
    var data = new FormData();
    data.append("image", image);
    data.append("imageBlurred", imageBlurred);
    data.append("projectId", projectId);
    console.dir(data);
    return fetch(`/api.php?action=imgUpload`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      body: data,
    })
      .then((response) => response.text())
      .then(
        (text) => {
          console.log(text);
        }
        // if the response is a JSON object
      )
      .catch((e) => {
        console.error(e);
      });
  }
  /**
   * takes the high res image uploaded to the "2560/" directory and scales both the sharp and blurred versions
   * down to the selected size (representing height in pixels), and saves it into that size directory
   *
   * @param {string} sizeDir the size directory to scale the image to. example: "640/". the numeric value is the destination height of the image
   * @param {string} projectId the project id, used to find the highest res (originally uploaded) file as the source file
   * @memberof Api
   */
  async scaleImages(sizeDir: string, projectId: string) {
    return this.call("scaleImages", { dir: sizeDir, projectId: projectId });
  }
}
const api = new Api();
export default api;
