import React from "react";
import "./AlertConfirmDialog.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import {observer} from "mobx-react-lite";
import {useStores} from "../../store/Store";
/**
 * A component to embed an alert and confirm dialog in the page
 * which react to some base properties in the store
 *
 * @class AlertConfirmDialog
 */
const AlertConfirmDialog = ()=> {
  const store = useStores();
    return (
      <div>
        <Modal isOpen={store.ui.alertVisible} className={"dialog"}>
          <ModalHeader
            toggle={() => {
              store.ui.closeAlert();
            }}
          >
            {store.lang.txt[store.ui.alertTitle]
              ? store.lang.txt[store.ui.alertTitle]
              : "{" + store.ui.alertTitle + "}"}
          </ModalHeader>
          <ModalBody>
            <p>
              {store.lang.txt[store.ui.alertMessage]
                ? store.lang.txt[store.ui.alertMessage]
                : "{" + store.ui.alertMessage + "}"}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                store.ui.closeAlert();
              }}
            >
              {store.lang.txt[store.ui.alertButtonLabel]
                ? store.lang.txt[store.ui.alertButtonLabel]
                : "{" + store.ui.alertButtonLabel + "}"}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={store.ui.confirmVisible}>
          <ModalHeader>{store.lang.txt[store.ui.confirmTitle]}</ModalHeader>
          <ModalBody>
            <p>{store.lang.txt[store.ui.confirmMessage]}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                store.ui.setConfirmation(false);
              }}
            >
              {store.lang.txt[store.ui.confirmCancelLabel]}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                store.ui.setConfirmation(true);
              }}
            >
              {store.lang.txt[store.ui.confirmButtonLabel]}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );

}
export default observer(AlertConfirmDialog);
