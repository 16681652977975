import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import "./ContactDialog.css";
import { observer } from "mobx-react-lite";
import { useStores } from "../../store/Store";
/**
 * A component to embed an alert and confirm dialog in the page
 * which react to some base properties in the store
 *
 * @class AlertConfirmDialog
 */

const ContactDialog = () => {
  const Store = useStores();
  const changeHandler = (e, fieldName: string) => {
    Store.contact.model[fieldName].setValue(e.target.value);
  };
  return (
    <div>
      <Modal
        isOpen={Store.contact.contactVisible}
        size={"lg"}
        className={"dialog"}
      >
        <ModalHeader
          toggle={() => {
            Store.contact.hideContactForm();
          }}
        >
          {Store.lang.txt["contact_title"]}
        </ModalHeader>
        <ModalBody>
          <div>
            <div className={"content-box"}>
              <Row>
                <Col xs={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Store.lang.txt["contact_content"],
                    }}
                  />
                  <form>
                    {Store.contact.model.fields().map((f) =>
                      f === "lang" ? null : (
                        <FormGroup key={f}>
                          <Row>
                            <Col sm={12} md={2}>
                              <Label>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      Store.lang.txt[
                                        Store.contact.model[f].label
                                      ],
                                  }}
                                />
                                {Store.contact.model[f].isRequired ? (
                                  <sup>*</sup>
                                ) : null}
                              </Label>
                            </Col>
                            <Col sm={12} md={10}>
                              <Input
                                type={Store.contact.model[f].ui}
                                value={Store.contact.model[f].value}
                                valid={
                                  Store.contact.model.validated &&
                                  Store.contact.model[f].isValid
                                }
                                invalid={
                                  Store.contact.model.validated &&
                                  !Store.contact.model[f].isValid
                                }
                                onChange={(e) => {
                                  changeHandler(e, f);
                                }}
                              />
                              <FormFeedback>
                                {Store.contact.model.validated
                                  ? Store.lang.txt[Store.contact.model[f].error]
                                  : null}
                              </FormFeedback>
                            </Col>
                          </Row>
                        </FormGroup>
                      )
                    )}
                  </form>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col sm={4}></Col>
            <Col sm={4}>
              <Button
                size={"lg"}
                block
                className={"submit-button"}
                onClick={() => {
                  Store.contact.hideContactForm();
                }}
              >
                {Store.lang.txt["cancel"]}
              </Button>
            </Col>
            <Col sm={4}>
              <Button
                size={"lg"}
                block
                className={"submit-button"}
                onClick={async () => {
                  let success = await Store.contact.submitContactForm();
                  if (success) {
                    Store.contact.hideContactForm();
                    await Store.ui.alert(
                      "contact_success_title",
                      "contact_success"
                    );
                  }
                }}
              >
                {Store.lang.txt["send"]}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default observer(ContactDialog);
