const en = {
  schedule: "Horaire",
  schedule_title: "Mon horaire nomade",
  schedule_content: `
  <div class="schedule">
  <p>Vous trouverez ci-dessous mes lieux et dates approximatifs.</p>
    <table>
      <tbody>
          <tr>
              <th>Lieux</th>
              <th>Dates</th>
          </tr>
          <tr>
              <td>Egypte (le Caire, Alexandrie, Dahab)</td>
              <td>mai 2023 (confirmé)<br/>juin 2023 (non&nbsp;confirmé)</td>
          </tr>
          <tr>
              <td>France (Paris)</td>
              <td>juin ou juillet 2023 (non&nbsp;confirmé)</td>
          </tr>
          <tr>
              <td>Mexique (San José del Pacifico, ville d'Oaxaca, Puerto Escondido, Zippolite/Mazunte) </td>
              <td>Possiblement juillet 2023 (non&nbsp;confirmé)<br/>sept 2023 à dec 2023 (confirmé)</td>
              
          </tr>
      </tbody>
    </table>
  </div>`,
  required_field: "Ce champs est requis",
  site_title: "Le nomade quantique",
  doc_title: "Le nomade quantique",
  about: "À propos",
  about_title: "Bienvenu(e) au site du nomade quantique ",
  about_content: `
  <p><img src="/img/will.png" style="float:left"/>Mon nom est Will Eizlini et je suis un praticien certifié de la technique d'hypnose quantique <a href="https://dolorescannon.com/" target="_blank">(QHHT)</a> de Dolores Cannnon à Montréal, Québec. </p>
  <p>Pourquoi &laquo;&nbsp;Le nomade quantique&nbsp;&laquo; ? Mes origines sont français et canadienne, mais je ne vies plus au Canada ni en France de façon permanante. J'ai décidé de vivre de façon nomadique pour le moment. Je me sens appelé à voyager un peu partout dans le monde avec ce travail d'hypnose quantique  .</p>
  <p>QHHT fonctionne avec le niveau de transe le plus profond. C'est un niveau ou on peut accéder éventuellement à la guérison et à des réponses au-delà des limites du corps, du temps et de l'espace. Les gens viennent pour une séance de QHHT pour de l'auto-guérison, pour satisfaire leurs curiosités envers par rapport aux vies antérieures. Ils viennent pour la découverte de leurs objéctifs dans cette incarnation, ou tout simplement l'exploration de leurs nature profonde.</p><p>Si vous sentez que le QHHT c'est quelque chose qui vous attire, <a href="#book">réservez une séance&nbsp;!</a> Si vous avez des questions, n'hésitez pas à <a href="#contact">me contacter&nbsp;!</a> Je vous répondrai dans un jour ou deux.</p>`,
  what_qhht: "Qu'est-ce-que le QHHT?",
  what_qhht_title: "Qu'est-ce-que l'hypnose quantique?",
  what_qhht_content: `
<h3>QHHT est l'aboutissement de plus de 40 ans de travail</h3>
  <p><a href="http://dolorescannon.com" target="_blank"><img src="/img/Dolores.png" style="float:right"/></a>
  QHHT est une technique développée pendant 45 ans par la grande hypnotherapeute Dolores Cannon. Elle se considérait comme une chercheuse de histoires perdues et une voyageuse du temps. Elle a découvert spontanément la régression de vies antérieures lors d'une séance qui avait des objectifs beaucoup plus banals. Cela a commencé une aventure de plus de 45 ans dans la guérison et l'exploration des vies antérieures et des états de conscience au-delà du corp physique sur terre. Elle a documentés toutes ses découvertes dans 17 livres.
</p>
<p>L'un des aspects les plus convaincants de son travail c'est qu'elle n'a jamais voulu prouver des théories à priori. Elle se voyait d'avantage comme une journaliste et elle écrivait des livres pour diffuser les informations qui se manifestaient dans les séances. Les informations sont arrivées de manière complètement spontanée, et elle les a courageusement poursuivies. Le résultat final est la technique d'hypnose quantique. Dolores voulait à l'origine l'appeler "Régression avancée de le vies antérieures" mais s'est rendu compte que les vies antérieures n'étaient qu'une des nombreuses possibilités d'exploration et de guérison.</p>
<h3>En quoi le QHHT est-il différent des autres techniques d'hypnose&nbsp;?</h3>
<p>QHHT fonctionne à un niveau beaucoup plus profond que l'hypnose clinique typique. À travers le processus, le pratiquant tente de contacter le niveau de conscience le plus profond. Dolores l'a appelé le subconscient. Le subconscient, par contre, insiste sur le fait qu'il n'a pas de nom. Cependant, le subconscient dans la téchinque de Dolores n'est pas celui qu'on retrouve dans la psychologie scientifique occidentale. Au contraire, il ressemble plus à l'inconscient collectif de Jung, mais il ressemble aussi plus à une <em>super conscience</em> collective. Il est sans limites et au-delà des limites de la communications linguistique, mais il peut cependant converser d'une façon éloquante. Il est au-delà du temps et de l'espace.</p>
<img src="/img/inner-light.gif" alt="Inner Light" class="banner" />
<h3>L'hynose quantique est naturel et sans dangers</h3>

<p>Toute hypnose est de l'auto-hypnose, et toute guérison est une auto-guérison. Vous ne pouvez pas être hypnotisé contre votre volonté. Il est sans danger, comme une méditation guidée. Avec le QHHT, une personne entre en transe profonde, un état de conscience naturel que nous entrons tous les jours, deux fois par jour, juste avant de s'endormir, et juste avant de se réveiller.</p>
<img src="/img/natural.gif" class="banner"/>
<h3>Tout le monde vit-il des vies antérieures?</h3>
<p>La guérison n'est pas nécessairement liée à d'autre vies. Le QHHT est né d'une exploration de vies antérieures, et il y a parfois un lien très clair. Mais parfois les réponses se trouvent dans cette vie, ou les réponses ou la guérison que les gens recherchent n'ont carrément rien à voir avec le passé. </p><p>Parfois un changement est encore nécessaire parce que nous ne suivons pas nos cœurs et nous ne faisons pas ce nous sommes venu sur terre pour vivre.  Les humains en général sont souvent motivés par la peur, mais nous devons être motivés par l'amour. il n'est donc pas nécessaire de croire en des vies antérieures pour que QHHT fonctionne - l'important est d'être ouvert d'esprit et sincère dans votre quête de guérison et de connaissance. Vous n'avez pas besoin de croire en quoi que ce soit pour que QHHT fonctionne</p>
<img src="/img/past-lives.gif" class="banner" alt="Les vies antérieurs?" />
<h3>Au-delà des vies antérieures</h3>
<p>Au fur et à mesure que QHHT progressait, de nombreuses expériences au-delà des vies humaines ont commencé à émerger, des vies en forme d'objets inanimés; des vies d'animaux, d'arbres, d' insectes, même la conscience du vent, de l'eau, du feu, des étoiles, des roches, même éventuellement des œuvres d'art!. Ces derniers temps, l'accent mis sur les vies antérieures a cédé la place à d'autres expériences, à la fois dans un état désincarné, dans les dimensions de la couleur et d'énergies pures; dans des domaines subatomiques et des existences au niveau galactique. Au cours des dernières années, le contact se fait avec la vie au-delà de la terre, au-delà de l'existence physique planétaire, avec la lumière, l'esprit pur, et la "source". Nous contactons l'unité qui lie de toute existence</p>
<img src="/img/planets.gif" class="banner" />`,
  how_work: "Comment ça se déroule?",
  how_work_title: "Comment se déroule une séance&nbsp;?",
  how_work_content: `
  <p>
  Les séances sont longues. Elles peuvent durer de 4 à 7 heures. La plupart de ce temps est consacré à une entrevue. La séance d'hypnose elle-même se situe entre 1,5 et 3 heures. En moyenne, ils durent en moyenne environ 2 heures. Il est donc important d'avoir un horiaire libre le jour de la séeance. Je n'essaye jamais de voir plus qu'un client par jour, et de même une personne venant pour une session ne devrait pas être contrainte par le temps; vous ne devriez pas avoir besoin d'être quelque part à un moment précis dans le futur.
  <h3>L'entrevue</h3>
  <p> L'entrevue est un moment où nous parlons de ce que vous recherchez, des questions que vous souhaitez poser à votre subconscient / moi supérieur. Nous discutons de tout problème physique, mental, émotionnel ou spirituel pour lequel vous cherchez à guérir. Je recherche des informations pour m'aider à mieux vous aider. Si vous n'avez pas eu le temps de formuler des question avant la séamce, pas de problème&nbsp;: nous pouvons les formuler ensemble. </p>
   <p> Certaines personnes ne sentent pas le besoin de parler plus d'une heure. Pour certains,  4 heures de conversation ne suffisent pas. ce qui est important est qu'une personne dans sa séance aie le temps nécessaire pour s'exprimer</p>
     <img class="banner" style="max-width:500px" src="/img/interview.gif"/>
  <h3>L'induction</h3>
  <p>Une fois que nous serons prêts, vous vous allongerez et nous commencerons. L'induction utilise une séquence de visualisations guidées et des suggestions de relaxation pour vous ammener vers un état de relaxation profonde. Vous entrerez dans un état de bien-être en me permettant de vous guider doucement dans un état de conscience profonde.</p>
  <img src="/img/clouds.gif" class="banner" />
  <h3>Vies antérieures, souvenirs et autres expériences</h3>
  
  <p>
La première phase de la session QHHT après l'induction est un voyage à travers une série d'expériences transtemporelles. Il est de prévoir comment cela se deroulera à l'avance. Certaines personnes vivent des vies antérieures, en tant qu'humain , d'objets, d'arbres ou des vies d'animaux, voire des insectes. Certains vivent d'autres vies qui se déroulent parallelement aujourd'hui, ou revivent des souvenirs de leur vie actuelle. Certains peuvent ressentir la vie comme de l'énergie pure, d'autres encore plus abstraits que cela. Certaines personnes peuvent vivre la vie en tant qu'être extraterrestre. L'important est de faire confiance à ce qui se présente comme une expérience importante qui doit être comprise</p>
 <img src="/img/portals.gif" class="banner" />
  <h3>L'invocation du subconscient</h3>
  <p>Après environ 45 minutes après la première phase, il est temps de discuter avec votre &laquo;&nbsp;moi&nbsp;&raquo; le plus profond, le subconscient / le moi supérieur. Dans certaines séances, cette conscience émerge spontanément. Dans d'autres, je lui demande de se manifester. Nous faisont d'abord un scan corporel pour découvir des partis qui on besoin de guérison. Pendant ce temps, je poserai toutes les questions que nous avons préparées ensemble auparavant.</p>
  <img src="/img/sc.gif" class="banner"  />
  <h3>Conditionnement avec un mot-clé</h3>
  <p>Juste avant de terminer la session, si vous le souhaitez, je vais vous conditionner à entrer en transe avec un mot clé de votre choix. Ce mot-clé peut être utilisé que par nous, si nous faisont une autre session ensemble. Mais surtout, il peut être utilisé par vous pour entrer dans un état profond que vous pouvez utiliser comme méditation, ou pour obtenir des réponses à l'avenir.</p>
  <img class="banner" src="/img/key.gif" style="max-width:700px"/>
  <h3>Le réveil et l'après séance</h3>
  <p>Une fois la session terminée. Je vais vous reveiller soigneusement, et nous allons nous asseoir et discuter de vos impressions pendant quelques minutes ou plus. L'idée est de vous assurer que vous vous sentez bien et que vous êtes en mesure de rentrer chez vous en toute securité. Certaines personnes sortent d'états hypnotiques profonds assez fatigués, d'autres pas du tout.</p>
   <img class="banner" src="/img/count.gif"/>
  <h3>L'enregistrement</h3>
  <img src="/img/recording.gif" class="banner" />
  <p>Je vous enverrai par courriel l'enregistrement de la seance rédigé sans l'induction (pour que vous n'entrerez donc pas en transe en écoutant l'enregistrement). La plupart des gens se souviennent de ce qui s'est passé lors d'une séance. Mais comme un rêve, les details se dissipent rapidement. L'enregistrement est important car l'écouter renforcera la guérison qui a potentiellement eu lieu pendant séance. L'écoute pourra également continuer à fournir des conseils et des réponses dans les jours qui suivent.</p>
  <h3>Combien de séances me faut-il&nbsp;?</h3>
  <p>Dans le meilleur des cas, une séance suffit. Le meilleur résultat possible est que vous n'avez plus besoin de revenir me voir parce que vous avez crée une connexion directe avec votre propre source.</p>
  <p>Certaines personnes peuvent préférer faire le travail en plusieurs séances. Il n'y a pas de bonne ou de mauvaise façon d'aborder ce travail. Il s'agit vraiment de ce dont vous avez besoin pour obtenir les réponses et la guérison que vous recherchez.</p>
</p>`,
  prepare: "Comment se préparer",
  prepare_title: "Comment se préparer pour un séance",
  prepare_content: `<p>Une fois que vous avez décidé d'avoir une séance et que vous l'avez réservée avec moi, elle commence déjà. Soyez conscient des sensations qui viennent et des synchronicités qui se manifeste dans votre vie. Soyez conscient de vos rêves et de vos pensées. Vous pouvez constater que certaines réponses peuvent arriver avant la séance même. Le temps n'est pas linéaire&nbsp;!</p>
<h3>Préparez vos questions</h3>
<p>Prenez le temps d'écrire une liste de 5 à 10 questions auxquelles vous voulez que votre moi le plus profond réponde. Les questions peuvent concerner n'importe quoi. Si vous n'avez pas le temps de formuler les questions, c'est très bien&nbsp;nous les créerons ensemble </p>
<img src="/img/questions.gif" class="banner"/>
<h3> Venez comme vous êtes! </h3>
<p> QHHT ne nécessite aucun effort particulier de votre part. C'est facile, il suffit de vous présenter à la séance, exactement comme vous êtes. Si vous êtes malheureux, soyez malheureux. Si vous êtes anxieux, venez anxieux. Si vous êtes extrêmement sceptique, soyez sceptique! QHHT consiste à être qui vous êtes en ce moment sans avoir besoin de prouver quoi que ce soit à personne, pas même à vous-même. </p><p>Soyez juste ouvert aux possibilités, car le QHHT est géniale! c'est un voyage passionnant. Vous n'obtiendrez pas toujours ce que vous désirez, mais vous obtiendrez toujours ce dont vous avez besoin. Rien ne se produira que vous n'êtes pas prêt à confronter, et rien ne se produira qui compromettra votre sécurité et bien-être. </p>
<img src="img/come-as-u-are.gif" class="banner" style="max-width:600px"/>
<h3>Médicaments</h3>
<p> Si vous prenez des médicaments pour traiter des problèmes de santé, continuez à suivre les conseils de votre médecin. Si vous êtes diabétique et avez besoin de vérifier votre glycémie, vous allez pouvoir le faire, même sous hypnose, vous pourrez vous réveiller et tester votre glycémie. Certaines personnes viennent à une séance pour traiter des problèmes de toxicomanie. Si tel est le cas, ne vous inquiétez pas de devoir être &laquo;&nbsp;clean&nbsp;&raquo; pendant des jours avant la session. Venez aussi lucidement que possible, sans forcer. </p>
`,
  book_session: "Réserver une séance",
  book_session_title: "Réserver une séance",
  book_session_content: `
  <h3>Prix d'une séance: 200$</h3>
  <p>Les sessions sont presque toujours un minimum de 5 heures de travail, et donc le coût est de 200&nbsp;$. </p>
  <h3>Formulaire de demande de séance</h3>
  <p>Entrez tout les informations requisent dans le formulaire ci-dessous. Si vous preferrez, vous pouvez tout simplement m'envoyer un WhatsApp au +1 514 814 8751</p>
  `,
  label_name: "Nom et prénom",
  label_email: "Courriel",
  label_message: "Message",
  label_phone: "Téléphone",
  help_info:
    "Raccontez-moi un peu votre histoire, et ce que vous recherchez dans une séance. N'oubliez pas de mentionner toutes les conditions médicales dont vous souffrez.",
  submit_reservation: "Envoyer",
  book_form_title: "Forumulaire de réservation",

  contact: "Contacte",
  contact_title: "Contactez moi",
  contact_content: `<p>
Demande-moi n'importe quoi! Si vous avez des questions ou des commentaires, remplissez simplement le formulaire ci-dessous et cliquez sur le bouton Soumettre. Je vous répondrai dans un jour ou deux
</p><p>les noms de champs suivi de <sup>*</sup> sont obligatoires</p>`,
  label_question: "Question",
  help_question:
    "Veuillez remplir ce champ avec toutes les questions que vous pourriez avoir.",
  send: "Soumettre",
  cancel: "Annuler",
  invalid_email: "l'addresse courriel n'est pas valide",
  sending_request: "Envoi en cours ...",
  booking_sent_title: "Demande de séance envoyée!",
  booking_sent:
    "Nous vous avons envoyé un courriel confirmant votre demande. Vous aurez de mes nouvelles dans 48 heures!",
  mail_failed_title: "Je n'ai pas pu envoyer votre demande",
  mail_failed:
    "Je n'ai pas pu envoyer votre demande de contact ou de séance. Je suis désolé. Peut-être réessayer plus tard",
  ok: "OK",
};
export default en;
