import {makeObservable, observable} from "mobx";
import BaseModel from "../Model/BaseModel";
import Field, { FieldType } from "../Model/Field";

/**
 * BookingModel
 */
export default class BookingModel extends BaseModel {
    constructor() {
        super();
        makeObservable(this,
            {
                email:observable,
                name:observable,
                message:observable,
                phone:observable,
                lang:observable,
            })
    }
   email = new Field(this, "email", {
    type: FieldType.string,
    required: true,
    ui: "text",
    label: "label_email",
    validation: (value) => {
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(String(value).toLowerCase())) return null;
      else return "invalid_email";
    },
  });
   name = new Field(this, "name", {
    type: FieldType.string,
    required: true,
    ui: "text",
    label: "label_name",
  });
   phone = new Field(this, "phone", {
    type: FieldType.string,
    required: true,
    ui: "text",
    label: "label_phone",
  });
   message = new Field(this, "message", {
    type: FieldType.string,
    required: true,
    ui: "textarea",
    label: "label_message",
  });
   lang = new Field(this, "lang", {
    type: FieldType.string,
    required: false,
    ui: null,
  });
}
