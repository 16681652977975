import React, { Component } from "react";
import { Container } from "reactstrap";
import Section from "./Section";
import "./Page.css";
import qhhtBanner from "../assets/images/qhht-banner.png";
import manCloud from "../assets/images/man-sleeping.gif";
import BookSession from "./BookSession";
import ContactDialog from "./dialogs/ContactDialog";
import AlertConfirmDialog from "./dialogs/AlertConfirmDialog";
import { observer } from "mobx-react-lite";
/**
 * Page
 *
 */

const Page = () => {
  return (
    <div className={"page"}>
      <Container>
        <Section
          anchor={"about"}
          title={"about_title"}
          content={"about_content"}
          banner={qhhtBanner}
        />
        <Section
          anchor={"schedule"}
          title={"schedule_title"}
          content={"schedule_content"}
        />
        <Section
          anchor={"qhht"}
          title={"what_qhht_title"}
          content={"what_qhht_content"}
          banner={manCloud}
        />
        <Section
          anchor={"bqh"}
          title={"what_bqh_title"}
          content={"what_bqh_content"}
        />
        <Section
          anchor={"info"}
          title={"how_work_title"}
          content={"how_work_content"}
        />
        <Section
          anchor={"prepare"}
          title={"prepare_title"}
          content={"prepare_content"}
        />
        <BookSession />
        <ContactDialog />
        <AlertConfirmDialog />
      </Container>
    </div>
  );
};
export default observer(Page);
