import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import "./Section.css";
import {observer} from "mobx-react-lite";
import {State, useStores} from "../store/Store";
/**
 * Section
 *
 */

const Section = (props)=>{
    const store:State = useStores();
    return (
      <div className={"section"}>
        <a name={props.anchor} className={"anchor"} />
        <h2
          dangerouslySetInnerHTML={{
            __html: store.lang.txt[props.title],
          }}
        />
        {props.banner ? (
          <img src={props.banner} className={"banner"} />
        ) : null}
        <div className={"content-box"}>
          <Row>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: store.lang.txt[props.content],
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
}
export default observer(Section)