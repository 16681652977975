import {action, makeAutoObservable, makeObservable, observable} from "mobx";
import BookingModel from "../models/BookingModel";
import Api from "../Api/Api";
/**
 *
 */
export default class BookingState {
  root;
  model: BookingModel = new BookingModel();
  constructor(store) {
    makeAutoObservable(this);
    this.root = store;

  }

  showContactForm() {
    this.model = new BookingModel();
    this.model.init();
    this.contactVisible = true;
  }

  hideContactForm() {
    this.contactVisible = false;
  }

  async submitForm() {
    let valid = await this.model.validate();
    if (valid) {
      this.setSending();
      this.model.lang.setValue(this.root.lang.current);
      console.log(this.model.toJS());
      try {
        let response = await Api.sendBooking(this.model.toJS());
        if (response.success) {
          await this.root.ui.alert("booking_sent_title", "booking_sent", "ok");
          this.bookingSent();
        }
      } catch (e) {
        console.log(e);
        this.bookingFailed();
        await this.root.ui.alert("mail_failed_title", "mail_failed", "ok");
        return false;
      }
    }
    return false;
  }

  sending = false;
  setSending() {
    this.sending = true;
  }
  bookingSent() {
    this.sending = false;
    this.model.init({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  }

  bookingFailed() {
    this.sending = false;
  }

  async init() {
    this.model.init();
  }
}
