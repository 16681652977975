import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./theme.css";
import TopNav from "./components/TopNav";
import Page from "./components/Page";
class App extends Component {
  render() {
    return (
      <div className="App">
        <div>
          <TopNav />
          <Page />
        </div>
      </div>
    );
  }
}

export default App;
