import { observable, action, computed, when, makeAutoObservable } from "mobx";
export default class UiState {
  root;
  contructor(root) {
    this.root = root;
    makeAutoObservable(this);
  }
  alertTitle = "";
  alertMessage = "";
  alertVisible = false;
  alertButtonLabel = "";
  confirmTitle = "";
  confirmMessage = "";
  confirmation = null;
  confirmVisible = false;
  confirmButtonLabel = "";
  confirmCancelLabel = "";
  loginError = false;
  loggingIn = false;

  activeNav = null;
  setActiveNav(active) {
    this.activeNav = active;
  }
  async init() {
    return true;
  }
  async alert(title, message, alertButtonLabel = "ok") {
    this.alertTitle = title;
    this.alertMessage = message;
    this.alertButtonLabel = alertButtonLabel;
    this.alertVisible = true;
    return new Promise((resolve) => {
      when(
        () => !this.alertVisible,
        () => {
          resolve(true);
        }
      );
    });
  }
  closeAlert() {
    this.alertTitle = "";
    this.alertMessage = "";
    this.alertVisible = false;
  }

  async confirm(
    title,
    message,
    confirmLabel = "confirm",
    cancelLabel = "cancel"
  ) {
    this.confirmTitle = title;
    this.confirmMessage = message;
    this.confirmButtonLabel = confirmLabel;
    this.confirmCancelLabel = cancelLabel;
    this.confirmVisible = true;
    return new Promise((resolve) => {
      when(
        () => this.confirmation !== null,
        () => {
          resolve(this.confirmation);
          this.resetConfirm();
        }
      );
    });
  }
  resetConfirm() {
    this.confirmVisible = false;
    this.confirmMessage = "";
    this.confirmTitle = "";
    this.confirmation = null;
  }

  setConfirmation(value: boolean) {
    this.confirmation = value;
  }
}
