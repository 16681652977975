import {observable, action, when, toJS, configure, runInAction, makeAutoObservable,} from "mobx";
import LangState from "./LangState/LangState";
import UiState from "./UiState/UiState";
import BookingState from "./BookingState/BookingState";
import ContactState from "./ContactState/ContactState";
import React from "react";
//import RouterState from "./RouterState/RouterState";
/**
 * the App State Root Object
 */
export class State {
  constructor() {
    makeAutoObservable(this)
  }
  version = "0.2.5";
   lang = new LangState(this);
   ui = new UiState(this);
   initialized: boolean = false;
   contact: ContactState = new ContactState(this);
   book: BookingState = new BookingState(this);
   loading = true;

   async init() {
    if (this.initialized) {
      return true;
    }

    await this.lang.init("en");
    await this.ui.init();
    await this.contact.init();
    await this.book.init();

    action(() => {
      this.loading = false;
      this.initialized = true;
    })();
  }
}
const stores = new State();
stores.init();
export const storesContext = React.createContext(stores);
window.state = stores;
window.toJS = toJS;
export default stores;
/**
 * Le hook principal pour accéder aux stores
 *
 * utlitisation:
 * const {test,bla} = useStore();
 *
 * @return {RootStore} l'instance du Root Store
 */
export const useStores = () => React.useContext(storesContext);

/**
 * Un hook pour accéder à des observables plus profondement dans l'arborescence
 *
 * utlitisation:
 * const {level1} = useStorePath("test","deep","level1");
 *
 * @param paths une série d'arguments variable en string qui denote le path dans l'arborescene
 * @return {*}
 */
export const useStorePath = (...paths) => {
    const stores = useStores();
    let error = false;
    let current = stores;
    paths.forEach((path) => {
        if (current[path]) {
            current = current[path];
        } else {
            error = true;
        }
    });
    //if (error) console.error("useStorePath: path was invalid")
    return error ? null : current;
};
