const en = {
  schedule: "Schedule",
  schedule_title: "My Nomad Healing Schedule",
  schedule_content: `

  
  <div class="schedule">
  <p>Below are my approximate locations and dates.</p>
    <table>
      <tbody>
          <tr>
              <th>Location</th>
              <th>Dates</th>
          </tr>
          <tr>
              <td>Egypt (Cairo, Alexandria, Dahab)</td>
              <td>May 2023 (confirmed)<br/>June 2023 (unconfirmed)</td>
          </tr>
          <tr>
              <td>France (Paris)</td>
              <td>June or July 2023 (unconfirmed)</td>
          </tr>
          <tr>
              <td>Mexico (San José del Pacifico, Oaxaca city, Puerto Escondido, Zippolite/Mazunte) </td>
              <td>Possibly as early as July 2023 (unconfirmed)<br/>Sept 2023 - December 2023 (confirmed)</td>
              
          </tr>
      </tbody>
    </table>
  </div>
`,
  required_field: "Field is Required.",
  site_title: "Nomad healing",
  doc_title: "Nomad Healing",
  about: "About",
  about_title: "Welcome to Nomad Healing",
  about_content: `
  <p><img src="/img/will.png" style="float:left"/>My name is Will Eizlini and I am a certified practitioner of Dolores Cannon's Quantum Healing Hypnosis Technique <a href="https://dolorescannon.com/" target="_blank">(QHHT)</a> and Candace Craw Goldman's Beyond Quantum Healing <a href="https://www.quantumhealers.com/bqh" target="_blank">(BQH)</a>. </p>
  <p>Why "nomad healing" ? My background is French and Canadian, but I no longer live permanently in either Canada or France. I have decided to live nomadically for the time being. I have felt moved to be in motion and to do this work all over the world. I own nothing except what is in my suitcase.</p>
  <p>QHHT and BQH work with the deepest layer of trance which can access healing and knowledge beyond the limits of the body, time and space. People come for these sessions for self-healing, curiosity about past lives, and the exploration of their purpose. </p><p>If you feel like it's something that you are drawn to, <a href="#book">book a session! </a> If you have questions not answered on this site? Feel free to <a onClick="window.store.contact.showContactForm()">contact me!</a> I'll get back to you within a day or two. </p><p>All the artwork on this site is mine.</a></p>`,
  what_qhht: "What is QHHT?",
  what_qhht_title: "What is Quantum Healing Hypnosis Technique?",
  what_qhht_content: `
<h3>QHHT is the culmination of the life's work of Dolores Cannon</h3>
  <p><a href="http://dolorescannon.com" target="_blank"><img src="/img/Dolores.png" style="float:right"/></a>
  QHHT is a technique developed over 45 years by the late and great Dolores Cannon. She considered herself a researcher of lost knowledge and a historical time traveller. She spontaneously discovered past-life regression in a session with much more mundane goals. This began a 45+ year adventure in healing and exploration of past lives and contact and with states of consciousness beyond the physical bodies of earth, which she documented in 17 books.
</p>
<p>One of the most compelling aspect of her work is that she never came into it with a belief system that she set out to prove. She felt she was more like a reporter and wrote books to simply get the information out there. information about past lives and higher levels of existence  came through completely spontaneously, which she fearlessly pursued.The End result is Quantum Healing Hypnosis Technique. Dolores originally wanted to call it "Advanced Past Life Regression" But realised that past lives were just a one of many possibilities of exploration and healing</p>
<h3>How is QHHT different from other hypnosis techniques?</h3>
<p>QHHT operates a much deeper level than typical clinical hypnosis. Through the process the practitioner attempts to contact the deepest layer of consciousness in a person. Dolores called it the Subconscious. It however, insists that it does not have a name. It is not like the subconscious as it is understood in scientific western psychology. Rather it resembles more of the collective unconscious of Jung, but it is also more like a collective superconsciousness, It is boundless and beyond limits of understanding and words, and yet can communicate quite fluently in words.It is beyond time and space, </p>
<img src="/img/inner-light.gif" alt="Inner Light" class="banner" />
<h3>QHHT is safe and natural</h3>
<p>All Hypnosis is self hypnosis, and all healing is self healing. You cannot be hypnotized against your will. It is safe, as safe as a guided meditation that lasts on average 2 hours but often feels like 30 minutes. With QHHT a person goes into deep trance, a state nonethless that we go into every day, twice a day, just before going to sleep, and just before awakening from sleep</p>
<img src="/img/natural.gif" class="banner"/>
<h3>Does everyone experience past lives?</h3>
<p>Healing is not necessarily related to past lives. It's true that QHHT emerged from an exploration of past lives, and sometimes there is a very clear connection. Yet sometimes the answers are in this life, or, the answers or healing people seek have nothing to do with the past at all, Sometimes a shift is still required because we are not following our hearts and we are not doing what we are here to do.</p>
<p>
 People in general are often motivated by  fear, but we should be motivated by love. But one does not need to believe in past lives for QHHT to work --  the important thing is to be open minded and sincere in your quest for healing and knowledge. You do not need to believe in anything for QHHT to work</p>
<img src="/img/past-lives.gif" class="banner" alt="past lives" />
<h3>Beyond past lives</h3>
<p>As QHHT advanced many experiences beyond human past lives began to emerge, lives as inanimate objects, as animals, trees, insects, even air, wind, water, fire, stars, rocks, event <em>planets</em> themselves!. In recent times, the focus on the past lives, has given way to other experiences, both in and out of bodies, in dimensions of pure color and energy, of subatomic realms and existences on galactic levels. In recent years contact with life beyond earth, beyond planetary physical existence in physical bodies, with light, with pure spirit, with "source", the underlying unity of all existence</p>
<img src="/img/planets.gif" class="banner" />
  `,
  what_bqh: "What is BQH?",
  what_bqh_title: "What is Beyond Quantum Healing (BQH)?",
  what_bqh_content: `
  <h3>BQH emerged to address the need for remote healing</h3>
  <p>Dolores Canon did not do QHHT remotely. As a result, a sister school, BQH (Beyond Quantum Healing) emerged from one of her most senior students Candace Craw Goldman, to, among other things allow for remote sessions and for practitioners to have more freedom with their practice in general.</p>
  <p></p>
  <h3>Are remote BQH sessions safe?</h3>
  <p>I do believe that they are. Already our vast higher selves protect us during in person sessions, why should this be any different if a session is online? Hypnosis has a long tradition of being done remotely over the phone. I've spoken to many therapists who integrated remote phone based hypnotherapy into their practice as far back as the 1970s</p>
  <p>In the end within the QHHT community, there is a lot of fear about online sessions, but in fact these concerns do not bear under scrutiny. Since BQH was founded many practioners have done hundreds or thousands of online sessions, they have found it to be just as effective a practice as in person sessions.</p>
  <h3>What happens if the connection drops in the middle of a session?</h3>
  <p>We do an induction where we instruct people to wake up and restore the connection should it drop. It's fundamentally no more of an interruption than needing to go to the toilet —and that happens all the time in sessions. You would simply wake up, reconnect and then immediately will go back into trance</p>
  <p>If for some reason there is an actual internet blackout, in that case I would phone you to complete the session. On the off chance that communication is completely stopped one of two things will happen to a person under hypnosis. They will eventually spontaneously come out of trance, or fall asleep. Remember that deep trance is really not unlike sleep. Trance states are natural states that we experience every day.</p>
  <h3>Reasons for having an Online Session</h3>
  <p>There could be a number of reasons. One may be very far away from the nearest practitioners, but have a decent internet connection. A person may have physical condition where they are unable to move and must stay at home, or they may be recovering in a hospital. Someone may be immunocompromised or have some kind of condition that requires them to be isolated. </p>
  <p>With Covid-19 we've experience a global pandemic with lockdowns and strict rules. In Quebec the strictness of the lockdowns and curfews made having remote sessions an obvious choice, and a safe one too!</p>
  <p>People may also simply feel that an online session is what they would prefer, and are drawn to it. My advice for people seeking sessions is to only go with the online choice if it's something that feels right. If for whatever reason you have fears about doing it online, then don't!</p>
  <h3>Online sessions work just as well as in person sessions</h3>
  <p>One of the unexpected things that happen with remote healing of any modality is that we realize that we are far greater than our bodies, and as people, we are far more connected than we think we are. While technology can seem challenging for an emotional connection, There is a kind of psychic / emotional / empathic "Inner Net" connection that happens —the "Heart Net". Despite the distances one can feel a real connection that persists until the session is complete, even with interruptions</p>
  <p>For some people they may actually find that they are able to access an even deeper layer of trance, than they would in person. They feel they can more easily let go of their controlling surface minds if they are in their own space, alone. So in the end it really depends on context and predilection </p>
  `,
  how_work: "How do Sessions Work?",
  how_work_title: "How does it work? What goes on in a session?",
  how_work_content: `
  <p>
  Sessions are long. They can last anywhere from 4 to 7 hours. Most of that time is spent in an interview. The actual hypnosis session is anywhere between 1.5 - 3 hours. Typically they average at around 2 hours. Therefore it's important to make a day for the session open ended. I never try and see more than 1 person per day, and likewise a person coming for a session should not be constrained by time; you should not need to be anywhere at a specific time in the future.
  <h3>The Interview</h3>
  <p>The interview is a time when we just talk. We talk about you, about what you are looking for, about the questions you want to ask your subconsious/higher-self. We discuss any physical, mental, emotional, or spiritual issues you might be seeking healing for. I look for information that i can use to help me help you better. If you have not had time to formulate questions, we can work on them together.</p>
  <p>Some people may not feel the need to talk more than an hour. Some may feel that even 4 hours of talking is not enough. The important thing is that a person who come to a session gets what they need to express themselves</p>
  <img class="banner" style="max-width:500px" src="/img/interview.gif"/>
  <h3>The Induction</h3>
  <p>Once we are ready, you will lie down and we will begin. The induction uses a  sequence of guided imagery and relaxation suggestions to guide you into a state of deep relaxation. You will enter into a profound state of well-being as you allow for the my words to gently guide you into a deep state of awareness</p>
  <img src="/img/clouds.gif" class="banner" />
  <h3>Past Lives, Memories, and other Experiences</h3>
  
  <p>The first phase of the QHHT session after the induction is a journey through a series of experiences. It's hard to say what they can be. Some people experience past lives, as people, objects, trees or animal, even insects. Some experience other lives happening today, or simply memories from their current life. Some may experience life as pure energy, some even more abstract that this. Some people may experience life as an extraterrestrial being.Some may connect with dead relatives and friends. The important thing is to trust what comes up as an important experience that needs to be understood</p>
    <img src="/img/portals.gif" class="banner" />

  <h3>Invoking the Subconscious</h3>
  <p>After about 45 minutes to an hour after the first phase, It is time to bring forth your deepest self, the subconscious/higher-self. In some sessions this consciousness emerges spontaneously. In some I ask it to come forward. We'll first do a body scan and look for things in the body that need healing. While this is happening I will pose all the questions we have prepared together.</p>
      <img src="/img/sc.gif" class="banner"  />
  <h3>Conditioning with a keyword</h3>

  <p>Just before finishing the session, If you wish, i will condition you to respond to a keyword of your choice. This keyword can be used if we choose to have another session together, but most importantly, it can be used by you to enter into a deep state which you can use as a meditation, or to get answers in the future. </p>
  <img class="banner" src="/img/key.gif" style="max-width:700px"/>
  <h3>Waking up and Post Session</h3>
  <p>When the session is complete. I will carefully count you out, and we will sit and discuss your impressions for a few minutes, up to an hour. The idea is to make sure you feel well and are able to make your way in the world. Some people emerge from deep hypnotic states quite groggy, some not at all.</p>
  <img class="banner" src="/img/count.gif"/>
  <h3>The recording</h3>
  <p>I will send to you by email the recording of the session which has the induction edited out (so you won't go into trance listening to the recording). Most people do remember what happened in a session, but like a dream, it fades quickly. The recording is important because listening to it will reinforce the healing that potentially took place during the session and also may continue to provide guidance and answers in the days that come</p>
  <img src="/img/recording.gif" class="banner"/>
  <h3>How many sessions does it take ?</h3>
  <p>In the best case scenario, one session is enough.  The best possible outcome is that you never need to come see me again. The session will have opened up a connection between your conscious mind and your deepest self, and you won't need me anymore because you have a direct connection to your own source of knowledge.</p>
  <p>Some people may prefer to make the journey in several sessions. There is no right or wrong way to approach this work. It's really about what you need and the time you need to get the answers you seek, the relief  and healing you seek.</p>
</p>`,
  prepare: "How to Prepare",
  prepare_title: "How to Prepare for a Session",
  prepare_content: `<p>Once you've decided to have a session and you've booked it with me, The session already begins. Be aware of feeling arising, or synchronicities happening in your life. Be aware of dreams and thoughts. You may find that some answers may even come before the session happens. Time is not linear! </p>
<h3>Preparing the questions</h3>
<p>Take some time to write down a list of 5-10 questions that you want answered by your deepest and highest self. The questions can be about anything at all. If you do not have time to formulate the questions, that's OK! we'll do them together</p>
<img src="/img/questions.gif" class="banner"/>
<h3>Come as you are!</h3>
<p>QHHT requires no special effort on your part. It's easy, just show up to the session, exactly how you are. If you are unhappy come unhappy. If you are anxious, come anxious. If you are extremely skeptical, come skeptical! QHHT is about being who you are right now without needing to prove anything to anyone, not even yourself. Just be open to possibilities, because QHHT is fun! it's an exciting journey, but you will not always get what you want, but you will always get what you need. Nothing will come up that you are not ready to experience, and nothing will come up that will compromise your feeling of safety.</p>
<img src="img/come-as-u-are.gif" class="banner" style="max-width:600px"/>
<h3>Medication</h3>
<p>If you are taking medication to handle a health issue, then continue to follow your doctor's advice. If you are diabetic and need to check your blood sugar, you will be able to do so, even under hypnosis you will be able to wake up and test your blood sugar. Some people come to a session to deal with issues of substance abuse. If this is the case, don't worry about needing to be clean for days prior to the session. Come as lucidly as you can, without forcing. </p>
<h3>Conditions not treatable with QHHT</h3>
<p>People diagnosed with Schizophrenia or Schizoaffective Disorder are not treatable by QHHT. Otherwise there are no specific requirements, except that you are able to hear and are comfortable lying down on a bed. </p>
<p>Any other health issues are not a problem. For example if you have diabetes and need to test your blood sugar every hour, this is not a problem with hypnosis. You will be able wake up to check your blood sugar, and then go back into a deep state of trance.</p>
`,
  book_session: "Book a session",
  book_session_title: "Book a session",
  book_session_content: `
  <h3>Session Fee: $200</h3>
  <p>Sessions are almost always a minimum of 5 hours, and so the cost is $200.</p>
<h3>Booking A Session</h3> <p> Enter all the required information in the form below. If you prefer to simply phone me you may do so as well at +1 514-814-8751  </p>
  `,
  book_form_title: "Booking Form",
  label_name: "Full name",
  label_email: "Email",
  label_message: "Message",
  label_phone: "Phone",
  help_info:
    "Tell me a little bit about who you are and what you are seeking in a session. Be sure to mention any medical conditions you may have and healing you are seeking.",
  submit_reservation: "Send Request",
  contact: "Contact",
  contact_title: "Contact Me",
  contact_content: `<p>
  Ask me anything! If you have questions or comments. If you want to reach out to me, just fill in the from below and click on the submit button. I will get back to you within a day or two
</p><p>Fields  marked with a <sup>*</sup> are required</p>`,
  label_question: "Question",
  help_question: "Please fill in this field with any questions you may have.",
  send: "Send",
  cancel: "Cancel",
  invalid_email: "Email address is invalid",
  sending_request: "Sending Request ...",
  booking_sent_title: "Booking request sent!",
  booking_sent:
    "We've sent you an email confirming your request. You will hear from me with 48 hours!",
  mail_failed_title: "Unable to send your request",
  mail_failed:
    "I've been unable to send your contact or booking request. Apologies, perhaps try again later",
  ok: "OK",
};
export default en;
