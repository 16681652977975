import React, { Component, useState } from "react";
import "./TopNav.css";
import { State } from "../store/Store";
import "./TopNav.css";
import logo from "../assets/images/road-3.png";
import {
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";
import { useStores } from "../store/Store";
import { observer } from "mobx-react-lite";

const TopNav = () => {
  const store: State = useStores();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"top-nav"}>
      {/*        <img alt="logo" className={"logo"} src={logo} />

        <div className={"site-title"}>HealingHypnosis.ca</div>
        <nav>
          {store.sections.map((t) => (
            <a href={"/" + t} className={store.section === t ? "current" : ""}>
              {store.titles[t]}
            </a>
          ))}
        </nav>*/}
      <Navbar color="dark" dark expand="md" className={"fixed-top"}>
        <NavbarBrand href="/">
          <img alt="logo" className={"logo"} src={logo} />{" "}
          <span className={"site-title"}>{store.lang.txt["site_title"]}</span>
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#about">{store.lang.txt["about"]}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#schedule">{store.lang.txt["schedule"]}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#qhht">{store.lang.txt["what_qhht"]}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#bqh">{store.lang.txt["what_bqh"]}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#info">{store.lang.txt["how_work"]}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#prepare">{store.lang.txt["prepare"]}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#book">{store.lang.txt["book_session"]}</NavLink>
            </NavItem>
            <NavItem>
              <a
                href={""}
                className={"nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  store.contact.showContactForm();
                }}
              >
                {store.lang.txt["contact"]}
              </a>
            </NavItem>
            {/**/}
          </Nav>
          <NavbarText>
            <UncontrolledDropdown>
              <DropdownToggle nav caret>
                <span style={{ textTransform: "uppercase" }}>
                  {store.lang.current}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  active={store.lang.current === "en"}
                  onClick={() => {
                    store.lang.setLanguage("en");
                  }}
                >
                  EN
                </DropdownItem>
                <DropdownItem
                  active={store.lang.current === "fr"}
                  onClick={() => {
                    store.lang.setLanguage("fr");
                  }}
                >
                  FR
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
};
export default observer(TopNav);
