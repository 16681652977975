import React from "react";
import {
  Input,
  Button,
  FormGroup,
  FormText,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { State, useStores } from "../store/Store";
import { observer } from "mobx-react-lite";

/**
 * BookSession
 *
 */
const BookSession = () => {
  const store: State = useStores();
  const changeHandler = (e, fieldName: string) => {
    store.book.model[fieldName].setValue(e.target.value);
  };
  if (store.initialized || !store.initialized)
    return (
      <div className={"section"}>
        <a name={"book"} className={"anchor"} />
        <h2>{store.lang.txt["book_session_title"]}</h2>
        <div className={"content-box"}>
          <Row>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: store.lang.txt["book_session_content"],
                }}
              />

              <form>
                {store.book.model.fields().map((f) => {
                  let ret =
                    f === "lang" ? null : (
                      <FormGroup key={"BOOK" + f}>
                        <Row>
                          <Col sm={12} md={2}>
                            <Label>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    store.lang.txt[store.book.model[f].label],
                                }}
                              />
                              {store.book.model[f].isRequired ? (
                                <sup>*</sup>
                              ) : null}
                            </Label>
                          </Col>
                          <Col sm={12} md={10}>
                            {f === "message" ? (
                              <FormText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: store.lang.txt["help_info"],
                                  }}
                                />
                              </FormText>
                            ) : null}
                            <Input
                              type={store.book.model[f].ui}
                              value={store.book.model[f].value}
                              valid={
                                store.book.model.validated &&
                                store.book.model[f].isValid
                              }
                              invalid={
                                store.book.model.validated &&
                                !store.book.model[f].isValid
                              }
                              onChange={(e) => {
                                changeHandler(e, f);
                              }}
                            />
                            <FormFeedback>
                              {store.book.model.validated
                                ? store.lang.txt[store.book.model[f].error]
                                : null}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                    );
                  return ret;
                })}
                <FormGroup row>
                  <Col sm={12} md={2}>
                    {""}
                  </Col>
                  <Col sm={12} md={10} className={"submit-form"}>
                    <Button
                      size={"lg"}
                      block
                      className={"submit-button"}
                      disabled={store.book.sending}
                      onClick={() => store.book.submitForm()}
                    >
                      {
                        store.lang.txt[
                          store.book.sending
                            ? "sending_request"
                            : "submit_reservation"
                        ]
                      }
                    </Button>
                  </Col>
                </FormGroup>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    );
  else return null;
};
export default observer(BookSession);
