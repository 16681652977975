import {action, makeAutoObservable, observable} from "mobx";
import ContactModel from "../models/ContactModel";
import Api from "../Api/Api";
/**
 *
 */
export default class ContactState {
  root;
  model: ContactModel = new ContactModel();
  constructor(store) {
    makeAutoObservable(this)
    this.root = store;
  }

   contactVisible: boolean = false;

   showContactForm() {
    this.model = new ContactModel();
    this.model.init();
    this.contactVisible = true;
  }

   hideContactForm() {
    this.contactVisible = false;
  }

   async submitContactForm() {
    let valid = await this.model.validate();
    if (valid) {
      this.model.lang.setValue(this.root.lang.current);
      console.log(this.model.toJS());
      let response = await Api.sendContact(this.model.toJS());
      return response.success;
    }
    return false;
  }

  
  async init() {
    this.model.init();
  }
}
